import React from 'react';
import Layout from '../components/Layout';
import Phone from '../../assets/icons/phone.svg';
import Email from '../../assets/icons/email.svg';
import Seo from '../components/Seo';
import withTranslations from '../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';

const JoinUs = props => {
  const { t } = useTranslation();
  return (
    <>
      <Layout section=''>
        <Seo
          title={t('joinUs.seoTitle')}
          description={t('joinUs.seoDescription')}
          keywords={['jobs', 'trabajo', 'curriculum', 'unete']}
        />
        <div className='wrapper global-margin global-padding global-section'>
          <h1>{t('joinUs.letsBuild')}</h1>
          <p>{t('joinUs.sendUs')}</p>
          <p>{t('joinUs.weWant')}</p>
          <p className='contact-subtitle'>{t('joinUs.contactInfo')}</p>
          <div className='mail-contact-container'>
            <ContactLink icon={Email} link='mailto: contact@positive0.com' text='contact@positive0.com' />
          </div>
          <div className='buttons-container'>
            <div className='btn-mail-container'>
              <a href='mailto: contact@positive0.com'>
                <button className='btn-mail'>{t('joinUs.buttonMail')}</button>
              </a>
            </div>
          </div>
        </div>
      </Layout>
      <style jsx>{`
        .contact-subtitle {
          font-weight: bold;
        }

        .phone-contact-container {
          margin-bottom: 15px;
        }

        .buttons-container {
          display: flex;
          margin-top: 30px;
        }

        .btn-mail-container {
          margin-right: 10px;
        }

        .btn-phone {
          background-color: white;
        }

        @media only screen and (max-width: 600px) {
          h1 {
            font-size: 40px;
          }

          .buttons-container {
            display: flex;
            flex-direction: column;
          }

          .btn-mail-container {
            margin-right: 0px;
            margin-bottom: 10px;
          }

          .btn-mail {
            width: 100%;
          }

          .btn-phone {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

const ContactLink = ({ icon, link, text }) => {
  const Icon = icon;
  return (
    <>
      <a href={link}>
        <div className='contact-link'>
          <Icon width='1.3em' height='1.3em' />
          <li>{text}</li>
        </div>
      </a>

      <style jsx>{`
        .contact-link {
          display: flex;
        }

        li {
          list-style: none;
          font-size: 16px;
          margin-left: 13px;
        }
      `}</style>
    </>
  );
};

export default withTranslations()(JoinUs);
